import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import newsPic1 from '../assets/images/news-pic1.jpg'
import newsPic2 from '../assets/images/news-pic2.jpg'
import newsPic3 from '../assets/images/news-pic3.jpg'
import newsPic4 from '../assets/images/news-pic4.jpg'
import newsPic5 from '../assets/images/news-pic5.jpg'
import newsPic6 from '../assets/images/news-pic6.jpg'

const News1 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 因應肺炎疫情措施</title>
      <meta name="description" content="因應肺炎疫情措施" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>因應肺炎疫情措施</h1>
          </header>
          {/* <span className="image main">
            <img src={musclers2} alt="" />
          </span> */}
          <h4>
            因應肺炎升溫，為了維護所有會員健康權益，即日起將更新入場作業，以下措施敬請各位會員跟單次入場會員配合:
          </h4>
          <ul>
            <li>
              會員，教練暨所有員工，入場時一律測量額溫並使用酒精消毒雙手，有發燒現象(37.5度以上)，請務必就醫檢查，將謝絕進入場內。
            </li>
            <br />
            <li>
              教練於課程中全程皆配戴口罩，課間也會以酒精消毒。(會員可斟酌佩戴口罩入場)
            </li>
            <br />
            <li>場館內定時多次使用次氯酸水消毒環境及器材。</li>
            <br />
            <li>
              所有境外返國的會員(不論級數)，請遵守自主健康管理14天之規定，並提前告知櫃台，辦理會籍請假。
            </li>
          </ul>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic4} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={newsPic6} alt="" />
                </span>
              </div>
            </div>
          </div>
          <p>
            為維護大家的 健身環境品質， 三健客健身房 每週定期會有專人來做
            全方位的消毒清潔， 搭配數十台的空氣清淨機 讓您用的安心、練的開心
            也請大家記得勤洗手， 美好的健身環境 就由你我一同守護。
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default News1
